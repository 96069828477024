.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.components-form-demo-normal-login .login-form-button {
  width: 100%;
}

* {
  margin: 0;
}

.slick-prev {
  left: -2.5% !important;
  z-index: 1;
}
.slick-next {
  right: -2.5% !important;
  z-index: 1;
}
.slick-prev:before,
.slick-next:before {
  color: gray !important;
}
