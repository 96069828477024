.grid-container {
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}

.container {
  padding-left: 0;
  padding-right: 0;
  width: 100% !important;
}

.container *{
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.col-md-4 {
  width: 33.33333333%;
}

.background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.height-one {
  border: 2px solid white;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.cover {
  background-color: #222;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 7%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.cover-text > p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide {
  height: 0;
  bottom: 100%;
  transition: .5s ease;
  overflow: hidden;
  font-size: 3%;
}

.border:hover .slide  {
  bottom: 0;
  height: auto;
}

.border:hover .animate-text  {
  top: 62%
}
